import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ContentServices2 from "../components/Services/ContentServices2";


function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="pool-rehabs">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Pool Rehabs"
          Subheader="Pool Rehabs"
          bgimg={`${rpdata?.gallery?.[102]}`}
        />
        <ContentServices2 />

      </div>
    </BaseLayout>
  );
}

export default About;
